import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Helmet } from 'react-helmet'
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js/max'

import { formatPhoneNumber } from '../../../api/apply'

import InfoBox from '../../info-box'
import Modal from '../../modal'

import './single.scss'

const Months = [
  { value: '1', name: 'January', days: 31 },
  { value: '2', name: 'February', days: 28 },
  { value: '3', name: 'March', days: 31 },
  { value: '4', name: 'April', days: 30 },
  { value: '5', name: 'May', days: 31 },
  { value: '6', name: 'June', days: 30 },
  { value: '7', name: 'July', days: 31 },
  { value: '8', name: 'August', days: 31 },
  { value: '9', name: 'September', days: 30 },
  { value: '10', name: 'October', days: 31 },
  { value: '11', name: 'November', days: 30 },
  { value: '12', name: 'December', days: 31 },
]

const conditionListLeft = [
  { key: 'alcohol', value: 'Alcohol or Substance Abuse' },
  { key: 'alzheimers', value: "Alzheimer's disease or Dementia" },
  { key: 'oxygen', value: 'Any condition requiring use of oxygen' },
  { key: 'chf', value: 'Congestive Heart Failure(CHF)' },
  { key: 'heart', value: 'Heart Attack or Stroke' },
]
const conditionListRight = [
  { key: 'hiv', value: 'HIV or AIDS' },
  { key: 'melanoma', value: 'Internal cancer, melanoma or leukemia' },
  { key: 'kidney', value: 'Kidney disease requiring dialysis' },
  { key: 'als', value: "Lou Gehrig's Disease(ALS)" },
  { key: 'parkisons', value: "Neuromuscular(Parkinson's, CP, MS)" },
]

class Single extends Component {
  defaultFormState = {
    type: 'life',
    first: '',
    last: '',
    gender: '',
    month: '',
    day: '',
    year: '',
    ft: '',
    in: '',
    weight: '',
    tobacco: '',
    coverage: '5000',
    conditions: ['none'],
    address: '',
    zip: '',
    phone: '',
    email: '',
    disabled: 'disabled',
  }

  constructor(props) {
    super(props)
    this.state = this.defaultFormState

    this.universalleadid = React.createRef()
    this.singleform = React.createRef()
    this.handleChange = this.handleChange.bind(this)
    this.handleButtonChange = this.handleButtonChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidUpdate = (prevProps) => {
    let formKey
    for (formKey in this.defaultFormState) {
      if (prevProps.captive.data[formKey] !== this.props.captive.data[formKey]) {
        let val = this.props.captive.data[formKey]
        if (formKey === 'month' && val !== '') {
          val = val.toString()
        }
        this.setState({
          [formKey]: val,
        })
      }
    }
    window.jcf.refreshAll()
  }
  handleSubmit = (event) => {
    event.preventDefault()

    if (this.universalleadid.current.value === '') {
      // Force the loader up front before looping starts
      this.props.preSubmitApplication()
      setTimeout(
        function () {
          this.handleSubmit(event)
        }
          .bind(this)
          .bind(event),
        500,
      )
    } else {
      let data = {}
      let stateKey
      for (stateKey in this.state) {
        let val = this.state[stateKey]
        if (stateKey === 'month') {
          val = parseInt(this.state[stateKey], 10)
        } else if (stateKey === 'phone') {
          const phoneNumber = parsePhoneNumberFromString(val, 'US')
          val = phoneNumber.nationalNumber
        } else if (stateKey === 'conditions') {
          val = val.join(',')
        }
        data[stateKey] = val
      }
      data.leadid = this.universalleadid.current.value

      this.props.submitData(data)

      const type = this.props.captive.type
      let subId = 'CMI_LifeInsuranceCompanion_Organic'
      if (this.props.captive.src !== '') {
        subId = this.props.captive.src
      }

      const page = 'thank-you'

      this.props.submitDob(data.year)

      //Submit the application and add Promise logic
      this.props
        .submitApplication()
        .then((response) => {
          // Update our data layer before navigating
          const { captive } = this.props
          if (window.dataLayer) {
            window.dataLayer.push({
              type: captive.type,
              src: captive.src,
              phone: formatPhoneNumber(captive.data.phone),
              zip: captive.data.zip,
            })
          }

          navigate('/' + page + '/?sub_id=' + subId)
        })
        .catch((err) => {
          // Catch with nothing
        })
    }
  }
  handleConditionsChange = (event) => {
    this.handleChange(event, true)
  }
  handleChange = (event, isConditions = false) => {
    let id = event.target.id
    const type = event.target.type
    let val = type === 'checkbox' ? event.target.checked : event.target.value

    if (id === 'phone') {
      const phoneNumber = new AsYouType('US').input(val)
      val = phoneNumber
    } else if (isConditions) {
      let conditions = this.state.conditions

      if (val) {
        if (!conditions.includes(id)) {
          conditions.push(id)
        }
      } else {
        if (conditions.includes(id)) {
          conditions = conditions.filter((item) => item !== id)
        }
      }

      val = conditions
      id = 'conditions'
    }

    this.setState({ [id]: val }, () => {
      this.checkDisabled()
      if (['month', 'day', 'year'].includes(id)) {
        this.checkDob()
      }
    })
  }
  handleButtonChange = (e, id, val) => {
    e.preventDefault()
    this.setState({ [id]: val }, () => {
      this.checkDisabled()
    })
  }
  handleModalLink = (e, type) => {
    e.preventDefault()
    this.props.toggleModal(type)
  }
  determineChecked = (id) => {
    return this.state.conditions.includes(id)
  }
  checkDisabled = () => {
    let disabled = true
    let phoneValid = false
    const phoneNumber = parsePhoneNumberFromString(this.state.phone, 'US')
    if (phoneNumber) {
      phoneValid = phoneNumber.isValid()
    }

    disabled =
      this.state.first === '' ||
      this.state.first.length < 3 ||
      this.state.last === '' ||
      this.state.last.length < 3 ||
      !phoneValid ||
      this.state.email === '' ||
      this.state.email.length < 6 ||
      this.state.month === '' ||
      this.state.day === '' ||
      this.state.year === '' ||
      this.state.gender === '' ||
      this.state.address === '' ||
      this.state.address.length < 5 ||
      this.state.coverage === '' ||
      this.state.household === '' ||
      this.state.ft === '' ||
      this.state.in === '' ||
      this.state.weight === '' ||
      this.state.tobacco === ''

    this.setState({ disabled: disabled })
  }
  checkDob = () => {
    if (this.state.month !== '' && this.state.day !== '') {
      const day = parseInt(this.state.day, 10)
      const month = parseInt(this.state.month, 10)
      this.props.submitData({ day: day, month: month })
    }

    if (this.state.month !== '' && this.state.day !== '' && this.state.year !== '') {
      this.props.submitDob(this.state.year)
    }
  }
  determineButtonClass = (id, val) => {
    return 'btn btn-tertiary' + (this.state[id] === val ? ' picked' : '')
  }
  renderDays = () => {
    let dayCount = 31
    for (let d = 0; d < Object.keys(Months).length; d++) {
      if (Months[d].value === this.state.month) {
        dayCount = Months[d].days
      }
    }
    let items = []
    for (let i = 1; i <= dayCount; i++) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>,
      )
    }
    return (
      <select value={this.state.day} id="day" onChange={this.handleChange}>
        <option value="">Day</option>
        {items}
      </select>
    )
  }
  renderYears = () => {
    let items = []
    for (let i = 2000; i >= 1910; i--) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>,
      )
    }
    return (
      <select value={this.state.year} id="year" onChange={this.handleChange}>
        <option value="">Year</option>
        {items}
      </select>
    )
  }

  render = () => {
    const { captive = {}, className = '' } = this.props
    const { api = {} } = captive
    let message = api.error ? api.message : ``
    let alertClasses = api.error ? `error` : `success`
    return (
      <div id="apply-flow" className={className}>
        <div className="contacts-form alt text-center">
          <div id="single">
            {api.error && api.message && (
              <InfoBox text={message} classNames={alertClasses} />
            )}
            <form
              action="#"
              className="form-validation"
              noValidate="novalidate"
              ref={this.singleform}
            >
              <input
                id="leadid_token"
                name="universal_leadid"
                type="hidden"
                value=""
                ref={this.universalleadid}
              />
              <div className="row">
                <div className="col half-width">
                  <label htmlFor="first">First Name</label>
                  <input
                    maxLength="25"
                    type="text"
                    id="first"
                    data-required="true"
                    value={this.state.first}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col half-width">
                  <label htmlFor="last">Last Name</label>
                  <input
                    maxLength="25"
                    type="text"
                    id="last"
                    data-required="true"
                    value={this.state.last}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col half-width inline-buttons">
                  <div className="label-only full-width">
                    <label htmlFor="gender">Gender</label>
                  </div>
                  <button
                    className={this.determineButtonClass('gender', 'male')}
                    onClick={(e) => {
                      this.handleButtonChange(e, 'gender', 'male')
                    }}
                  >
                    Male
                  </button>
                  <button
                    className={this.determineButtonClass('gender', 'female')}
                    onClick={(e) => {
                      this.handleButtonChange(e, 'gender', 'female')
                    }}
                  >
                    Female
                  </button>
                </div>
                <div className="col half-width">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="text"
                    maxLength="15"
                    id="phone"
                    data-required="true"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col full-width label-only">
                  <label htmlFor="month">Date of Birth</label>
                </div>
                <div className="col third-width first">
                  <select
                    id="month"
                    value={this.state.month}
                    onChange={this.handleChange}
                  >
                    <option value="">Month</option>
                    {Months.map(({ value, name }) => (
                      <option key={value} value={value}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col third-width">{this.renderDays()}</div>
                <div className="col third-width last">{this.renderYears()}</div>
              </div>

              <div className="row">
                <div className="col full-width label-only">
                  <label htmlFor="existing">How much coverage are you looking for?</label>
                </div>
                <div className="col full-width">
                  <select
                    id="coverage"
                    onChange={this.handleChange}
                    value={this.state.coverage}
                  >
                    <option value="5000">$5,000</option>
                    <option value="10000">$10,000</option>
                    <option value="20000">$20,000</option>
                    <option value="50000">$50,000</option>
                    <option value="100000">$100,000</option>
                    <option value="200000">$200,000</option>
                    <option value="300000">$300,000</option>
                    <option value="400000">$400,000</option>
                    <option value="500000">$500,000</option>
                    <option value="750000">$750,000</option>
                    <option value="1000000">$1,000,000</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col full-width label-only">
                  <label htmlFor="existing">Height</label>
                </div>
                <div className="col half-width">
                  <select id="ft" value={this.state.ft} onChange={this.handleChange}>
                    <option value="">Feet</option>
                    <option value="3">3'</option>
                    <option value="4">4'</option>
                    <option value="5">5'</option>
                    <option value="6">6'</option>
                    <option value="7">7'</option>
                  </select>
                </div>
                <div className="col half-width">
                  <select id="in" value={this.state.in} onChange={this.handleChange}>
                    <option value="">Inches</option>
                    <option value="1">1"</option>
                    <option value="2">2"</option>
                    <option value="3">3"</option>
                    <option value="4">4"</option>
                    <option value="5">5"</option>
                    <option value="6">6"</option>
                    <option value="7">7"</option>
                    <option value="8">8"</option>
                    <option value="9">9"</option>
                    <option value="10">10"</option>
                    <option value="11">11"</option>
                    <option value="12">12"</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col half-width">
                  <label htmlFor="weight">Weight</label>
                  <input
                    maxLength="4"
                    type="text"
                    id="weight"
                    data-required="true"
                    value={this.state.weight}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col half-width inline-buttons">
                  <div className="full-width label-only">
                    <label htmlFor="tobacco">Do you use tobacco?</label>
                  </div>
                  <button
                    className={this.determineButtonClass('tobacco', 'No')}
                    onClick={(e) => {
                      this.handleButtonChange(e, 'tobacco', 'No')
                    }}
                  >
                    No
                  </button>
                  <button
                    className={this.determineButtonClass('tobacco', 'Yes')}
                    onClick={(e) => {
                      this.handleButtonChange(e, 'tobacco', 'Yes')
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col full-width label-only">
                  <label htmlFor="existing">
                    Have you been treated or diagnosed for any of these medical
                    conditions?
                  </label>
                </div>
                <div className="col half-width">
                  <div className="options">
                    <input
                      type="checkbox"
                      value="none"
                      id="none"
                      onChange={this.handleConditionsChange}
                      checked={this.determineChecked('none')}
                    />
                    <label htmlFor="none">I have no medical conditions</label>
                  </div>
                  {conditionListLeft.map(({ key, value }) => (
                    <div className="options" key={key}>
                      <input
                        type="checkbox"
                        value={key}
                        id={key}
                        onChange={this.handleConditionsChange}
                        checked={this.determineChecked(key)}
                      />
                      <label htmlFor={key}>{value}</label>
                    </div>
                  ))}
                </div>
                <div className="col half-width">
                  {conditionListRight.map(({ key, value }) => (
                    <div className="options" key={key}>
                      <input
                        type="checkbox"
                        value={key}
                        id={key}
                        onChange={this.handleConditionsChange}
                        checked={this.determineChecked(key)}
                      />
                      <label htmlFor={key}>{value}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="row">
                <div className="col half-width">
                  <label htmlFor="address">Street Address</label>
                  <input
                    maxLength="80"
                    type="text"
                    id="address"
                    data-required="true"
                    value={this.state.address}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col half-width">
                  <label htmlFor="zip">Zip</label>
                  <input
                    maxLength="5"
                    type="text"
                    id="zip"
                    data-required="true"
                    value={this.state.zip}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col full-width">
                  <label htmlFor="email">Email</label>
                  <input
                    maxLength="80"
                    type="email"
                    id="email"
                    data-required="true"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="btn-wrap text-center">
                {api.pending && <CircularProgress />}
                {!api.pending && (
                  <button
                    type="submit"
                    onClick={this.handleSubmit}
                    disabled={this.state.disabled}
                    className={`btn` + (this.state.disabled ? ` disabled` : ``)}
                  >
                    View Plans
                  </button>
                )}
                {this.state.disabled && (
                  <p className="required">All fields are required.</p>
                )}
                {api.error && <p className="required">{api.message}</p>}
              </div>
            </form>
          </div>
          <div>
            <p className="tcpa">
              By clicking the button and submitting this form, I agree that I am 18+ years
              old and agree to the{' '}
              <Link to="/" onClick={(e) => this.handleModalLink(e, 'privacy')}>
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link to="/" onClick={(e) => this.handleModalLink(e, 'terms')}>
                Terms &amp; Conditions
              </Link>
              . By clicking the button and submitting this form, I provide my signature
              giving express consent to receive marketing communications via artificial or
              pre-recorded voice, emails, live phone calls, pre-recorded calls, postal
              mail, text messages via SMS or MMS and other forms of communication
              regarding offers Medicare Supplement, Medicare Advantage, Part D, Final
              Expense or other products from PolicyScout, GuideToInsure, InsureaLife
              and/or one or more of it’s{' '}
              <Link to="/" onClick={(e) => this.handleModalLink(e, 'partners')}>
                marketing partners
              </Link>{' '}
              and agents to the number(s) and/or email I provided, including a mobile
              phone, even if I am on a state or federal Do Not Call and/or Do Not Email
              registry using an automated telephone dialing system. Agents are not
              connected with or endorsed by the U.S. government or the federal Medicare
              program. The list of companies participating are subject to change. I
              understand that my consent to receive communications is not required as a
              condition of purchase and I may revoke my consent at any time by contacting
              us via 1-833-259-7339 or email at opt.out@policyscout.com so that you may be
              placed on our do-not-call and/or our do-not-email list. To receive quotes
              without providing consent, please call at 1-833-656-0487.
            </p>
            <Modal type="partners" />
          </div>
          <ul className="security-list">
            <li>
              <img src="/images/Norton-Logo.png" alt="description of thing" />
            </li>
            <li>
              <img src="/images/McAfee-Logo.png" alt="description of thing" />
            </li>
            <li>
              <img src="/images/BBB-Logo.png" alt="description of thing" />
            </li>
          </ul>
        </div>
        <Helmet key={`jornaya`}>
          <script key={`jornayascript`} id="LeadiDscript" type="text/javascript">
            {`
                        (function() {
                        var s = document.createElement('script');
                        s.id = 'LeadiDscript_campaign';
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = '//create.lidstatic.com/campaign/f9212193-4afd-a49a-83a4-b38cd5943d4b.js?snippet_version=2';
                        var LeadiDscript = document.getElementById('LeadiDscript');
                        LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
                        })();
                    `}
          </script>
          <noscript
            key={`jornayanoscript`}
          >{`<img src='//create.leadid.com/noscript.gif?lac=175deec7-46ac-376d-3c9f-2e7d7b607660&lck=f9212193-4afd-a49a-83a4-b38cd5943d4b&snippet_version=2' />`}</noscript>
        </Helmet>
      </div>
    )
  }
}

export default Single
